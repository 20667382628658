import axios from 'axios'
import communLangage from '@/commun/commun.langage.js';

export default {
     name: 'Competences',

     data() {
          return {
               afficherPlus: false,
               titres:{}
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          }
     },

     methods: {
          recupererTexte() {
               axios.get(process.env.BASE_URL + 'assets/textes/competences/competences.json').then(result => {
                    this.titres = this.langage == "fr" ? result.data.fr : result.data.en;

               });
          }
     },

     watch: {
          langage: function () {
               this.recupererTexte();
          }
     },

     created() {
          this.recupererTexte();
     }
}